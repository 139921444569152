
    @import "tenants/tenants-agugiaro-e-figna/visite_mulino/javascript/src/stylesheets/visite_mulino_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  


.tile {
  &::after {
    z-index: 1;
    content: "";
    max-height: 25%;
    height: 3rem;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    pointer-events: none;
    background: linear-gradient(180deg, transparent, rgba(0,0,0,0.4));
  }
}

.embed-video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.highlighted {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.user-label {
  color: white;
  font-weight: 600;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
}

.microphone-icon {
  border-radius: 50%;
  background-color: rgba(0,0,0,.5);
  transition: all 0.05s ease-in-out;
}

.admin-controls-container {
  .admin-controls {
    opacity: 0;
    border-radius: 1rem;
    transition: opacity 0.25s ease-in-out;
    background-color: rgba(0,0,0,.5);
    
  }
  &:hover .admin-controls {
    opacity: 1;
  }
}

.small{
  font-size: 0.875rem !important;
}



    @import "tenants/tenants-agugiaro-e-figna/visite_mulino/javascript/src/stylesheets/visite_mulino_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[partial-form-component] {
  overflow-y: auto;
  min-height: var(--min-height);
  .disclaimer {
    background-color: rgba($primary, .5);
    border-radius: $input-border-radius;
    max-height: 40vh;
    overflow-y: auto;
  }

  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
  }
}

[form-feedback-component] {
  background-color: white;
  text-align: left;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  border-radius: 0.75rem;
}



    @import "tenants/tenants-agugiaro-e-figna/visite_mulino/javascript/src/stylesheets/visite_mulino_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[user-password-editing-form-component] {
  .form-group {
    margin-bottom: 2rem;
  }
}


    @import "tenants/tenants-agugiaro-e-figna/visite_mulino/javascript/src/stylesheets/visite_mulino_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

@mixin thumb-styles($selector) {
  #{$selector}::-webkit-slider-thumb {
    @content
  }
  #{$selector}::-moz-range-thumb {
    @content
  }
  #{$selector}::-ms-thumb {
    @content
  }
}

[youtube-popup-component] {
  $youtube-progress-bar-marker-size: 2rem;
  $bar-height: .375rem;

  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: $black;
  z-index: $zindex-youtube-popup;
  height: 100vh;

  .next-icon {
    position: absolute;
    top: 50%;
    right: 5%;
  }

  .prev-icon {
    position: absolute;
    top: 50%;
    left: 5%;
  }

  .youtube-popup-prevent-action {
    background-color: transparent;
  }

  .video-progress-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: $bar-height;
    border-radius: .25rem;
  }

  .video-progress {
    width: 101%;
    height: 100%;
    position: absolute;
    top: 0;
    background: $primary;
  }

  $progress-icon-size: 2rem;
  .video-progress-icon {
    font-size: $progress-icon-size;
    top: -($progress-icon-size * 1.5);
    left: $progress-icon-size * 0.5;
    width: 100%;
    text-align: right;
    color: $white;
    text-shadow: 0 0 1px $black;

    &.paused {
      opacity: 0;
    }
  }
  
  .video-progress-background {
    width: 100%;
    height: 100%;
    background-color: $white;
    border: 1px solid $gray-200;
  }

  .youtube-popup-pause i {
    font-size: 5rem;
  }

  .youtube-popup-close {
    position: absolute;
    top: 0rem;
    right: 1rem;
    color: $white;
    cursor: pointer;
    font-size: 2rem;
  }

  .youtube-popup-video-container {
    /* Required video-16by9 */
    height: 100vh;
    position: relative;
  }

  .youtube-progress-bar-container {
    position: fixed;
    bottom: 35px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 75%;

    .youtube-progress-bar {
      width: 100%;
      appearance: none;
      height: $bar-height * 6;
      outline: none;
      position: absolute;
      top: -$bar-height * 3;
      left: 0;
      background-color: transparent;
      z-index: 1;

      @include thumb-styles("&") {
        appearance: none;
        width: $youtube-progress-bar-marker-size + .15rem;
        height: $youtube-progress-bar-marker-size + .15rem;
        border-radius: 100%;
        border: 1px solid transparent;
        background: transparent !important;
        cursor: pointer;
        position: relative;
        z-index: 3;
      }
    }

    &.youtube-progress-bar-interaction-inactive {
      display: none;
    }

    .youtube-progress-bar-chapter {
      position: absolute;
      top: unset;
      width: $youtube-progress-bar-marker-size;
      height: $youtube-progress-bar-marker-size;
      background-color: $primary;
      border: 1px solid $primary;
      color: $white;
      border-radius: 100%;
      cursor: pointer;
      z-index: 2;

      .far {
        font-size: $youtube-progress-bar-marker-size * 0.5;
      }

      &.active {
        // background-color: $gray-300;
        background-color: $white;
        border: 0 solid $primary;
        box-shadow: 0 0 0 $youtube-progress-bar-marker-size * 0.175 inset $primary;
      }

      &.correct {
        background-color: $green;
        border-color: $green;
      }

      &.wrong {
        background-color: $red;
        border-color: $red;
      }

      &.youtube-progress-bar-chapter-end {
        left: 100%;
      }
    }
  }

  @include media-breakpoint-down(xs) {

    .youtube-popup-video-container {
      &.video-16by9:not(.reverse-ar) {
        height: auto;
        padding-bottom: 56.25%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      /* Reverse aspect ratio to 9/16 when vcode_mobile is active. */
      &.video-16by9.reverse-ar {
        video, iframe {
          height: 177.78vw !important;
          width: 56.25vh !important;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {

    .youtube-progress-bar-container {

      .youtube-progress-bar {
        @include thumb-styles("&") {
          width: $youtube-progress-bar-marker-size * 0.75 + .115rem;
          height: $youtube-progress-bar-marker-size * 0.75 + .115rem;
        }
      }

      .youtube-progress-bar-chapter {
        width: $youtube-progress-bar-marker-size * 0.75;
        height: $youtube-progress-bar-marker-size * 0.75;
        .far {
          font-size: $youtube-progress-bar-marker-size * 0.30;
        }
      }
    }
  }
}


    @import "tenants/tenants-agugiaro-e-figna/visite_mulino/javascript/src/stylesheets/visite_mulino_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[video-popup-component] {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1091;
  height: 100vh;

  .video-popup__embed-container {
    height: 100vh;
    position: relative;
  }
}


    @import "tenants/tenants-agugiaro-e-figna/visite_mulino/javascript/src/stylesheets/visite_mulino_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[modal-survey-component] {
  background-color: $secondary;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  input[type=radio] {
    accent-color: #000;
  }

  .close-icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .progress-bar-container {
    height: .3rem;
    background-color: $primary;
    .progress-bar {
      top: 0;
      left: 0;
      background-color: #735338;
      transition: width .5s ease-in-out;
    }
  }
}


    @import "tenants/tenants-agugiaro-e-figna/visite_mulino/javascript/src/stylesheets/visite_mulino_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[mark-interaction-component] {
  $mark-size: 1.5rem;

  &.mark {
    width: $mark-size !important;
    height: $mark-size !important;
    background-color: $primary;
    border: 1px solid $primary;
    color: $white;
    z-index: 5;

    &.correct {
      background-color: $success;
      border-color: $success;
    }

    &.wrong {
      background-color: $danger;
      border-color: $danger;
    }

    .far {
      font-size: $mark-size * .5;
    }

    @include media-breakpoint-down(sm) {
      width: $mark-size * .75 !important;
      height: $mark-size * .75 !important;

      .far {
        font-size: $mark-size * .3;
      }
    }
  }
}


    @import "tenants/tenants-agugiaro-e-figna/visite_mulino/javascript/src/stylesheets/visite_mulino_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

@import "../../stylesheets/page/visite_mulino_events_table.scss";
@import "../../stylesheets/page/visite_mulino_fullscreen_modal.scss";

[admin-page-report-component] {
  min-height: calc(100vh - #{$header-height});
  input[type=radio] {
    border-radius: 50%;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid #000;
    transition: 0.2s all linear;
    position: relative;
    margin-left: 40px;
    &:checked {
      border: 5px solid black;
    }
  }

  p {
    display: inline;
  }

  .content-modal {
    input:disabled {
      background-color: #ECE4DE !important;
    }
  }

  .table-events {
    td {
      white-space: nowrap;

      &:first-child {
        width: 70%;
      }
    }
  }
}


    @import "tenants/tenants-agugiaro-e-figna/visite_mulino/javascript/src/stylesheets/visite_mulino_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[vote-ranking-entry-component] {
  &.active {
    background-color: $gray-200;
  }

  .vote-ranking-entry-reward-marker {
    border-radius: 2rem;
    min-width: 3rem;

    background-color: $yellow;
    color: $white;
  }

  .vote-ranking-entry-avatar {
    position: relative;
    width: 3rem; 
    height: 3rem; 
    border-radius: 100%;
    background-color: $primary;
    font-size: 1.5rem;
    color: $white;
  }
}

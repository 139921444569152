[event-materials] {
  a.rec-container, .doc-container { transition: background-color .25s; }

  .doc-container:hover,
  .doc-container:active,
  a.rec-container:hover,
  a.rec-container:active {
    background-color: #fff !important;
  }

  .rec-container,
  .doc-container,
  .banner {
    border-radius: 1rem;
  }

  .rec-container,
  .doc-container {
    .text > div:last-child {
      line-height: 1;
    }
  }

  .doc-container {
    .icon {
      width: 3rem;
      height: 3rem;
      border-radius: .75rem;
    }
  }

  .rec-container {
    .thumbnail {
      min-width: 3.75rem;
      height: 3.75rem;
      border-radius: .75rem;
    }
  }
}

    @import "tenants/tenants-agugiaro-e-figna/visite_mulino/javascript/src/stylesheets/visite_mulino_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.face-recognition {
  video, canvas {
    width: 320px;
    max-width: 50vw;
  }

  canvas {
    position: absolute;
    left: 0;
  }
}

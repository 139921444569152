
    @import "tenants/tenants-agugiaro-e-figna/visite_mulino/javascript/src/stylesheets/visite_mulino_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[like-interaction-component] {
  pointer-events: none;
  width: 1.5rem;
  height: 40vh;
  position: absolute;
  bottom: 0;
  right: 0;

  .heart {
    opacity: 1;
    position: absolute;
    bottom: 0;
    right: 0;
    display: none;
    color: $player-red-color;
    font-size: 1.5rem;
  }

  @keyframes flowOne {
    0% {
      opacity: 0;
      bottom: 0;
      left: 14%
    }
    40% { opacity: .8; }
    50% {
      opacity: 1;
      left: 0;
    }
    60% { opacity: .2; }
    80% { bottom: 80%; }
    100% {
      opacity: 0;
      bottom: 100%;
      left: 18%
    }
  }

  @keyframes flowTwo {
    0% {
      opacity: 0;
      bottom: 0;
      left: 0;
    }
    40% { opacity: .8; }
    50% {
      opacity: 1;
      left: 11%
    }
    60% { opacity: .2; }
    80% { bottom: 60%; }
    100% {
      opacity: 0;
      bottom: 80%;
      left: 0;
    }
  }

  @keyframes flowThree {
    0% {
      opacity: 0;
      bottom: 0;
      left: 0;
    }
    40% { opacity: .8; }
    50% {
      opacity: 1;
      left: 30%
    }
    60% { opacity: .2; }
    80% { bottom: 70%; }
    100% {
      opacity: 0;
      bottom: 90%;
      left: 0;
    }
  }
}



    @import "tenants/tenants-agugiaro-e-figna/visite_mulino/javascript/src/stylesheets/visite_mulino_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[page-event-registration-component] {
  background-color: $secondary;
  .progress-bar-container {
    height: .3rem;
    background-color: $primary;
    .progress-bar {
      top: 0;
      left: 0;
      background-color: #735338;
      transition: width .5s ease-in-out;
    }
  }
}


    @import "tenants/tenants-agugiaro-e-figna/visite_mulino/javascript/src/stylesheets/visite_mulino_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

$tab-header-height: 49px;

.live-event-sidebar {
  z-index: 1019;
  position: relative;
  color: var(--theme-color);
  border-bottom: 1px solid $gray-300;
  background-color: var(--theme-background);

  &--fullscreen {
    position: relative;
    height: var(--inner-height, 100vh);
    top: 0;

    .interactions-area {
      max-height: calc(var(--inner-height, 100vh) - #{$tab-header-height});
      overflow-y: scroll;
    }
  }

  @include media-breakpoint-up(lg) {
    &:not(.live-event-sidebar--fullscreen) {
      position: sticky;
      top: $header-height;
      height: calc(100vh - #{$header-height});
      border-bottom: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    &.live-event-sidebar--fullscreen {
      height: 100%;
    }
  }

  @include media-breakpoint-down(md) {
    &.live-event-sidebar--fullscreen {
      ::v-deep [versus-widget-component] {
        .question-card {
          .question-text {
            font-size: 1rem;
          }

          .answer {
            font-size: .875rem;
            padding-bottom: .5rem !important;
            padding-top: .5rem !important;
          }
        }
      }
    }
  }
}

.list-group-item-action {
  width: auto !important;
  background-color: var(--theme-background);
  color: var(--theme-color);

  &.active {
    color: inherit;
    font-weight: 500;

    &:after {
      content: '';
      left: 0;
      bottom: 0;
      width: 100%;
      position: absolute;
      border-bottom: 3px solid $primary !important;
    }
  }
}

[data-toggle="collapse"] {
  &:not(.collapsed) > .open-icon,
  &.collapsed > .close-icon {
    display: none;
  }
  
  &.collapsed i.fa-chevron-up {
    transform: rotate(180deg);
  }
}

.btn-link {
  color: inherit;
  text-decoration: none;
  box-shadow: none;
}

.interactions-area {
  max-height: 100%;
  
  @include media-breakpoint-up(lg) {
    overflow-x: hidden;
    overflow-y: scroll;
  }

  @include ie {
    height: 100%;
  }
}

.banner {
  background-color: var(--theme-color);
  color: var(--theme-background);
}


    @import "tenants/tenants-agugiaro-e-figna/visite_mulino/javascript/src/stylesheets/visite_mulino_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[page-live-event-survey-item-component] {

  .btn {
    i {
      font-size: 1.55rem;
    }
  }

  .form-label {
    font-size: 1.25rem;
  }

}

.option {
  width: 12.5rem;
  height: 12.5rem;
  color: #000;
  background-color: $primary;
  border-radius: 2.25rem;
  transition: all .25s;

  &:not(.prevent-hover-effect):hover {
    background-color: rgba(#7B4922, .35);
    color: rgba(255,255,255,.5);

    .image {
      opacity: .5;
    }
  }
  
  p {
    font-size: .75rem;
    margin-bottom: 0 !important;
  }

  .image {
    font-size: 2rem;
    overflow: hidden;
    border-radius: 1.5rem;
    background-color: $primary;
    opacity: 1;
    transition: opacity .25s;
  }

  div.image.w-50{
    padding-bottom: 50%;
  }
}
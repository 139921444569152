
    @import "tenants/tenants-agugiaro-e-figna/visite_mulino/javascript/src/stylesheets/visite_mulino_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[page-user-events-component] {
  min-height: calc(100vh - #{$header-height});

  span > p {
    display: inline;
    margin-bottom: 0;
  }

  a.visit-container {
    transition: background-color .25s;
    border-radius: 1rem;
    text-decoration: none !important;

    .icon {
      width: 4rem;
      height: 4rem;
      border-radius: .75rem;
    }

    &:hover,
    &:active {
      background-color: #fff !important;
    }
  }
}

// Header height
$header-height: 72px;
$header-height-sm: $header-height;

// Colors
$secondary: #c9b3a2;
$primary: #d9cabe;
$dark-brown: #b09580;
$dark: #1b1b1b;
$golden: #CFB26D;
$green: #4DAB7570;
$gray-AA: #AAAAAA;


// Buttons
$btn-font-weight: 600;
$btn-font-size: 1rem;

$btn-padding-y-sm: 0.5rem;
$btn-padding-x-sm: 1rem;
$btn-font-size-sm: 0.6875rem;
$btn-border-radius-sm: 0.75rem;

$btn-font-size-lg: 1.125rem;
$btn-border-radius-lg: 0;

// Fonts
$neutra-text-font: "Neutra Text", roboto, sans-serif;
$font-family-base: $neutra-text-font;
$font-family-default: $neutra-text-font;
$enable-responsive-font-sizes: true;

$font-weight-normal: normal;
$font-weight-bold: bold;

$headings-font-weight: $font-weight-bold;
$input-border-radius: .5rem;
$custom-select-border-radius: $input-border-radius;
$btn-border-radius: 1rem;



@import "~src/stylesheets/fandom_variables";

$visite-mulino-colors:(
  "dark-brown": $dark-brown,
  "green": $green,
  "gray-AA": $gray-AA
);

$colors: map-merge($colors, $visite-mulino-colors);
$theme-colors: map-merge($theme-colors, $visite-mulino-colors);

$display1-size: 3.5rem;

// Headings
$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.5;
$h6-font-size: $font-size-base;
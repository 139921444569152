
    @import "tenants/tenants-agugiaro-e-figna/visite_mulino/javascript/src/stylesheets/visite_mulino_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[navbar] {
  height: $header-height;
  background-color: $secondary;

  input[type="radio"] {
    accent-color: black;
  }

  .form-check-label{
    font-weight: 400;
  }

  .btn {
    line-height: 1;
    border-radius: .75rem;
  }

  .btn-small {
    line-height: 1;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: .75rem;
  }

  .btn-save {
    color: white !important;
    text-decoration: none;
  }

  .btn-close {
    background-color: #DCCEC365;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: .75rem;
  }
  .alert-success {
    background-color: rgba(77, 171, 117, .3);
    color: #409D63;
  }

  button {
    white-space: nowrap;
  }

  h5 {
    font-size: 1rem;
  }

  @include media-breakpoint-down(sm) {
    .email {
      max-width: 5rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

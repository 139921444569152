[fullscreen-modal] {
  &.content-modal {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    overflow-y: scroll;
    z-index: $zindex-fixed;
    background-color: $light;
    
    .content-modal-container {
      position: absolute;
      bottom: 0;
    }
    
    .btn-close {
      top: 1rem;
      right: 1rem;
      width: 2.5rem;
      height: 2.5rem;
      line-height: 1;
      position: absolute;
      border-radius: .75rem;
      background-color: #DCCEC365;
    }

    .form-control, .custom-select {
      background-color: $light !important;
      border: 1px solid $primary !important;
      &::placeholder {
        color: #000 !important;
      }   
    }
  }
  
}
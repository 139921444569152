
    @import "tenants/tenants-agugiaro-e-figna/visite_mulino/javascript/src/stylesheets/visite_mulino_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-media-component] {
  position: relative;

  background-color: var(--theme-background);
  color: var(--theme-color);

  @include media-breakpoint-down(md) {

    /* Forced square rappresentation on mobile */
    .modal-container.ar-16by9 {
      padding-bottom: 100%;

      .fa-8x {
        font-size: 5rem;
      }
    }
  }
}


    @import "tenants/tenants-agugiaro-e-figna/visite_mulino/javascript/src/stylesheets/visite_mulino_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.notification-header {
  position: absolute;
  top: 0;

  .tag {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.7);
    color: #10111D;
    max-height: 24px;
    padding: 6px 8px;

    .title {
      font-size: .75rem;
      line-height: 13px;
    }
  }
}

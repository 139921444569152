tbody > tr > td {
  padding: 0 0 .75rem 0 !important;
  height: 1px;
}

tbody > tr > td > div {
  padding: .75rem;
}

tbody > tr > td:first-child > div {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
  font-size: .875rem;
}

tbody > tr > td:last-child > div {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .5rem;
}

td p {
  display: inline;
}

table button.btn {
  font-weight: normal !important;
}
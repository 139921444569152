body {
  background-color: $secondary;
}

@include media-breakpoint-up(xl) {
  .w-limiter {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-height: 1080px) {
  .mh-100vh, 
  .mh-100vh-header {
    height: 1080px !important;
    min-height: auto !important;
  }
}

strong {
  font-weight: bold !important;
}

.btn {
  text-transform: uppercase;
}

.footer_container {
  position: fixed;
  bottom: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;
  text-align: right;
}

.form-control, .custom-select {
  background-color: $primary !important;
  border: 0px !important;
  &::placeholder {
    color: #000 !important;
  }
  
}

a:not(.btn) {
  color: #000 !important;
  text-decoration: underline;
}

.text-underline {
  text-decoration: underline;
}

.modal-content {
  border-radius: 1rem !important;
}

    @import "tenants/tenants-agugiaro-e-figna/visite_mulino/javascript/src/stylesheets/visite_mulino_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[check-in-step-component] {
  min-height: calc(100vh - 9.25rem);

  .opacity-0 {
    opacity: 0;
    z-index: -1;
  }

  .option {
    width: 12.5rem;
    height: 12.5rem;
    color: #000;
    background-color: $primary;
    border-radius: 2.25rem;
    transition: all .25s;

    &:hover {
      background-color: rgba(#7B4922, .35);
      color: rgba(255,255,255,.5);

      .image {
        opacity: .5;
      }
    }
    
    p {
      font-size: .75rem;
      margin-bottom: 0 !important;
    }

    .image {
      font-size: 2rem;
      overflow: hidden;
      border-radius: 1.5rem;
      background-color: $primary;
      opacity: 1;
      width: 50%;
      transition: opacity .25s;

      &:not(img) {
        padding-bottom: 50%;
      }
    }

    @include media-breakpoint-down(sm) {
      flex-grow: 1;
      height: auto;
      width: auto;
      border-radius: 1rem;

      .image {
        width: 3rem;
        height: 3rem;
        border-radius: .75rem;

        &:not(img) {
          padding-bottom: 0;
        }
      }
    }
  }


}

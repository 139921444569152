
    @import "tenants/tenants-agugiaro-e-figna/visite_mulino/javascript/src/stylesheets/visite_mulino_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[product-component] {
  .back {
    font-size: .75rem;
    font-weight: 500;
    float: left;
  }

  .f-small {
    font-size: .9rem;
  }

  .line-through {
    text-decoration: line-through;
  }

  .btn {
    border-radius: $player-border-radius;
  }

  
  @media (max-width: 1650px) {
    .buttons-container {
      flex-direction: column;

      :first-child {
        margin-right: 0 !important;
        margin-bottom: 0.5rem !important;
      }
    }
  }
}

.flickity-carousel-item {
  width: 100%;
  overflow: hidden;
  padding: 2px;

  &.skin-square {
    width: 60%;
    max-width: 250px;

    @include media-breakpoint-down(sm) {
      width: 40%;
    }
  }

  &.skin-vertical {
    width: 60%;
    max-width: 163px;

    @include media-breakpoint-down(sm) {
      width: 40%;
    }
  }

  img {
    border-radius: $player-border-radius;
  }

  > div {
    position: relative;
  }
}

.flashsale {
  &-text {
    font-size: .5rem;
    font-weight: 600;
    color: $player-red-color;

    .countdown {
      padding: 2px .25rem;
      background-color: $player-red-color;
    }
  }

  &-icon {
    width: .875rem;
    height: .875rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $player-red-color;
  }
}

.price {
  &--full {
    font-size: 1rem;

    &.text-muted {
      color: $player-grey !important
    }
  }

  &--discount {
    font-size: 2rem;
  }

  &--flashsale {
    color: $player-red-color;
  }
}

.fixed-bottom {
  .fixed-buttons-container {
    box-shadow: 0px 0px 50px rgba(0, 0, 0, .3);
  }
}


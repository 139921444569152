
    @import "tenants/tenants-agugiaro-e-figna/visite_mulino/javascript/src/stylesheets/visite_mulino_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[embed-multistream-component] {
  .secondary-video-stream {
    width: var(--secondary-stream-width);
    max-width: 33%;
    padding: 16px 8px;
    transition: transform .25s ease-in-out;

    &:hover {
      transform: scale(0.97);
    }

    video {
      border-radius: 4px;
    }
  }
}


    @import "tenants/tenants-agugiaro-e-figna/visite_mulino/javascript/src/stylesheets/visite_mulino_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[admin-page-people-component] {
  min-height: calc(100vh - #{$header-height});

  .custom-control {
    padding-right: 2rem !important;
  }

  .custom-control-label::after,
  .custom-control-label::before {
    right: -1.5rem;
    left: auto;
    top: 2px;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $dark;
    background-color: $light;
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>")
  }
}


    @import "tenants/tenants-agugiaro-e-figna/visite_mulino/javascript/src/stylesheets/visite_mulino_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[page-event-check-in-component] {
  background-color: $secondary;

  h6 {
    font-size: .875rem;
  }

  .qr-code-container {
    border-radius: 2rem;
    border: 1px solid #AE8E75;
    padding: 2rem;
  }

  img.qr-code-placeholder {
    width: 16rem;
    height: 16rem;
  }
}

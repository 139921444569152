
    @import "tenants/tenants-agugiaro-e-figna/visite_mulino/javascript/src/stylesheets/visite_mulino_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

@import "../../stylesheets/page/visite_mulino_event_materials.scss";
@import "../../stylesheets/page/visite_mulino_squared_options.scss";
@import "../../stylesheets/page/visite_mulino_fullscreen_modal.scss";
[admin-page-event-component] {
  min-height: calc(100vh - #{$header-height});

  .content-modal {

    .form-control {
      background-color: #ECE4DE20 !important;
      border: 1px solid $primary !important;
      &::placeholder {
        color: #000 !important;
      }
    }

    .recipe-title-container {
      .recipe-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .option {
      border-radius: 1.25rem;
      background-color: #C9B3A265;

      .image {
        border-radius: .75rem !important;
      }
    }

    .custom-control.custom-checkbox {
      padding-left: 1rem;
    }

    .custom-control-label::before,
    .custom-control-label::after {
      left: -1rem;
    }

    .custom-control-label::before {
      border: none;
    }
  }
}

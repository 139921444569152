
    @import "tenants/tenants-agugiaro-e-figna/visite_mulino/javascript/src/stylesheets/visite_mulino_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

@import "../../stylesheets/page/visite_mulino_event_materials.scss";

[page-event-materials-component] {
  min-height: calc(100vh - #{$header-height});

  span > p {
    display: inline;
    margin-bottom: 0;
  }

  .section-title {
    font-size: .875rem;
  }

  a {
    text-decoration: none !important;
  }
}


    @import "tenants/tenants-agugiaro-e-figna/visite_mulino/javascript/src/stylesheets/visite_mulino_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

@import "../../stylesheets/page/visite_mulino_events_table.scss";
@import "../../stylesheets/page/visite_mulino_fullscreen_modal.scss";

[page-all-events-component] {
  min-height: calc(100vh - #{$header-height});

  .btn-close {
    line-height: 1;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: .75rem;
    background-color: #DCCEC365;
  }

  .alert {
    position: fixed;
    top: 1rem;
    display: inline;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2010 !important;
  }

  .manage-container {
    .btn {
      width: 2rem;
      height: 2rem;
      border-radius:.75rem;
    }
  }

  .alert {
    border: none;
  }

  .alert-success {
    background-color: rgba(77, 171, 117, .3);
    color: #409D63;
  }

  input[type=radio] {
    border-radius: 50%;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid #000;
    transition: 0.2s all linear;
    position: relative;
    margin-left: 40px;
    &:checked {
      border: 5px solid black;
    }
  }

  td:first-child {
    width: 50%;
  }
  td:nth-child(2) {
    width: 180px;
  }
  td, td button {
    white-space: nowrap;
  }
}


    @import "tenants/tenants-agugiaro-e-figna/visite_mulino/javascript/src/stylesheets/visite_mulino_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[products-list] {
  display: flex;
  flex-direction: column;

  .list-container {
    overflow-y: scroll;
    flex: 1;

    @include media-breakpoint-down(md) {
      overflow-y: visible;
    }
  }
  
  .placeholder {
    &-image {
      width: 9rem;
      height: 9rem;
      color: var(--gray);
      background: var(--gray-light);

      @include media-breakpoint-down(md) {
        width: 6rem;
        height: 6rem;

        i {
          font-size: 2em;
        }
      }

      i {
        opacity: 0.75;
      }
    }

    &-text {
      color: var(--gray);
    }
  }

  .card {
    border: 0;
    cursor: pointer;

    &-body {
      display: flex;

      &__image {
        width: 7rem;
        min-width: 7rem;
        height: auto;
        overflow: hidden;

        @include media-breakpoint-down(sm) {
          width: 4rem;
          min-width: 4rem;
        }

        img {
          border-radius: .875rem;
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        flex: 1;
        width: calc(100% - 7rem - 12px);

        @include media-breakpoint-down(sm) {
          width: calc(100% - 4rem - 12px);
        }

        .card-title {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;

          @include media-breakpoint-down(sm) {
            font-size: 1rem !important;
          }
        }

        .card-text {
          .line-through {
            text-decoration: line-through;
          }
        }
      }

      &__actions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    &.flashsale {
      .card-body {
        &__image {
          .flashsale-icon {
            width: 1.25rem;
            height: 1.25rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: .5rem;
            background-color: $player-red-color;
          }

          img {
            border: 1px solid $player-red-color;
          }
        }

        .main-info {
          color: $player-red-color;
        }

        .flashsale-text {
          font-size: .5rem;
          font-weight: 600;
          color: $player-red-color;

          .countdown {
            padding: 2px .25rem;
            background-color: $player-red-color;
          }
        }
      }
    }
  }
}
